/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

interface Rule {
  LTO?: number; // Maximum Decimal Place Length When Less Than One
  MTO?: number; // Maximum Decimal Place Length When More Than One
  EDPL?: boolean; // Enforce Decimal Places
  rounding?: boolean; // Whether to round
  isFloor?: boolean; // Takes effect when rounding is false.
}

interface Rules {
  [key: string]: Rule;
}

const rules: Rules = {
  default: {
    LTO: 2,
    MTO: 2,
    EDPL: true,
    rounding: true,
    isFloor: false,
  },
  ETH: {
    LTO: 4,
    MTO: 1,
    EDPL: false,
    rounding: true,
    isFloor: false,
  },
};

/**
 * @description Retains the specified number of decimals
 *
 * @param {number} value - Numbers that require decimals
 * @param {boolean} hasSign - Whether to add + prefix when the value is greater than 0.
 * @param {Rule} ruleType - Pre-set rules.
 * @param {Rule} customSet - Whether to enforce decimal places. For example: decimalizeNumber(100, 2, true, true) will return 100.00 and decimalizeNumber(100, 2, false, true) will return 100
 * @return {string} - Formated number string
 */
export const decimalizeNumber = (
  value: number,
  hasSign = false,
  ruleType = "default",
  customSet: Rule = {},
  placeholder = "--"
): string => {
  value = Number(value);
  if (isNaN(value) || value === 0) return placeholder;

  const rule = rules[ruleType];
  const {
    LTO = rule.LTO as number,
    MTO = rule.MTO as number,
    EDPL = rule.EDPL,
    rounding = rule.rounding,
    isFloor = rule.isFloor,
  } = customSet as Rule;

  const DPL = value >= 1 ? MTO : LTO;

  if (rounding) {
    value = Math.round(value * Math.pow(10, DPL)) / Math.pow(10, DPL);
  } else {
    value = isFloor
      ? Math.floor(value * Math.pow(10, DPL)) / Math.pow(10, DPL)
      : Math.ceil(value * Math.pow(10, DPL)) / Math.pow(10, DPL);
  }

  let res: string = value.toString();

  if (EDPL) {
    const arr = res.split(".");
    const int = arr[0];
    const float = arr[1];
    const floatArr = [];
    for (let i = 0; i < DPL; i++) {
      floatArr.push("0");
    }
    if (float) {
      floatArr.splice(0, float.length, ...float.split(""));
    }
    res = int + "." + floatArr.join("");
  }

  return hasSign ? (value > 0 ? "+" : "") + res : res;
};

// Convert long numbers to abbreviations. For example: 1000 to 1.00K, 1000000 to 1.00M, 1000000000 to 1.00B
export const abbreviatedNumber = (value = 0, decimalLength = 2): string => {
  if (!value || isNaN(Number(value))) {
    value = 0;
  }
  let numStr;
  if (value >= 1000000000) {
    numStr = decimalizeNumber(value / 1000000000, false, "default", {
      MTO: decimalLength,
      LTO: decimalLength,
    });
    return numStr + "B";
  } else if (value >= 1000000) {
    numStr = decimalizeNumber(value / 1000000, false, "default", {
      MTO: decimalLength,
      LTO: decimalLength,
    });
    return numStr + "M";
  } else if (value >= 1000) {
    numStr = decimalizeNumber(value / 1000, false, "default", {
      MTO: decimalLength,
      LTO: decimalLength,
    });
    return numStr + "K";
  } else {
    return decimalizeNumber(value, false, "default", {
      MTO: decimalLength,
      LTO: decimalLength,
    });
  }
};

export const amountFormat = (
  value: number | string,
  decimalLength = 2,
  thousandths = true
): string => {
  if (isNaN(Number(value))) value = 0;
  const res = decimalizeNumber(Number(value), false, "default", {
    MTO: decimalLength,
    LTO: decimalLength,
  });
  if (!thousandths) return res;
  const arr = res.split(".");
  const integer = arr[0];
  const float = arr[1];
  const reverseList = integer.split("").reverse();
  const arr1 = [];
  for (let i = 0; i < reverseList.length; i++) {
    arr1.push(reverseList[i]);
    if ((i + 1) % 3 === 0 && i < reverseList.length - 1) {
      arr1.push(",");
    }
  }
  const integerWithThousandths = arr1.reverse().join("");

  const formatedData = integerWithThousandths + (float ? "." + float : "");
  return formatedData;
};
