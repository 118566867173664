/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import axios, { AxiosRequestConfig } from "axios";
const newsDomain = process.env.VUE_APP_API_DOMAIN;
const http = axios.create({
  baseURL: newsDomain,
  timeout: 6000,
});

http.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      process.env.NODE_ENV !== "production" &&
        console.log(response.config.url + " Response: ", response);
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

interface GetRequestConfig {
  url: string;
  config?: AxiosRequestConfig;
}

export const getRequest = <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<Array<T | undefined>> => {
  const params: GetRequestConfig = { url };
  
  config && (params.config = config);

  return new Promise((resolve) => {
    http
      .get<T>(url, config)
      .then((r) => resolve([undefined, r.data]))
      .catch((e) => resolve([e, undefined]));
  });
};

export const get = (url = '', params = {}, headers = {}) => {
  return axios({
    method: 'get',
    url,
    headers: { ...headers },
    params: Object.assign({}, params),
  })
}

export const post = (url = '', params = {}, headers = {}) => {
  return axios({
    method: 'post',
    url,
    headers: { ...headers },
    data: Object.assign({}, params)
  });
}
